<template>
  <div
    class="lds-ellipsis"
    :style="{ left }">
    <div :style="{ 'background-color': color }" />
    <div :style="{ 'background-color': color }" />
    <div :style="{ 'background-color': color }" />
    <div :style="{ 'background-color': color }" />
  </div>
</template>

<script>
export default {
  props: {
    left: {
      type: String,
      default: '20px'
    },
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style scoped>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px !important;
  height: 30px;
}
.lds-ellipsis div {
  position: absolute;
  top: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}

</style>
