<template>
  <div>
    <span v-if="count !== 0">
      {{ firstItem }} - {{ lastItem }} จาก {{ count }} รายการ
    </span>
    <div class="paginate">
      <div
        class="left"
        @click="changePage(-1)">
        <v-icon>mdi-chevron-left</v-icon>
      </div>
      <div
        class="right"
        @click="changePage(1)">
        <v-icon>mdi-chevron-right</v-icon>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    page: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    count: {
      type: Number,
      default: 0
    }
  },
  computed: {
    firstItem () {
      return ((this.page - 1) * this.perPage) + 1
    },
    lastItem () {
      const last = this.page * this.perPage
      return last > this.count ? this.count : last
    }
  },
  methods: {
    changePage (value) {
      if (this.count !== 0) {
        let result = (this.page + value) < 1 ? 1 : this.page + value
        const totalPage = Math.ceil(this.count / this.perPage)
        result = result > totalPage ? totalPage : result
        this.$emit('on-page-change', result)
      } else {
        const result = (this.page + value) < 1 ? 1 : this.page + value
        this.$emit('on-page-change', result)
      }
    }
  }

}
</script>

<style scoped>
.paginate {
  display: table;
  float: right;
  margin-left: 10px;
}
.paginate > div {
  display: table-cell;
  width: 27px;
  height: 24px;
  border: solid 1px #d2d2d2;
  background-color: #ffffff;
  text-align: center;
  cursor: pointer;
}
.left {
  border-radius: 20px 0 0 20px;
}
.right {
  border-radius: 0 20px 20px 0;
}
</style>
