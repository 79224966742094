<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row
      justify="space-between"
      class="head-search ma-0">
      <v-col
        cols="12"
        md="4">
        <WarehouseSelect
          v-model="warehouseSelected"
          :items="mapWarehouse"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          label="Warehouses"
          return-object
          outlined />
        <!-- <v-select
          v-model="warehouseSelected"
          :items="mapWarehouse"
          :menu-props="{ offsetY: true }"
          item-value="name"
          item-text="name"
          label="Warehouses"
          return-object
          auto-select-first
          outlined
          hide-details
          dense
          @input="selectSku = null"
        /> -->
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="7"
        xl="6"
        class="col-search">
        <search-by-tags-and-brand
          v-model="query"
          append-icon="mdi-magnify"
          tags-width="300px"
          :is-mobile="isMobile"
          @on-search="searchProduct()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        class="products-content">
        <v-data-table
          :headers="headers"
          :items="products"
          :items-per-page="options.itemsPerPage"
          :server-items-length="total"
          :loading="loading"
          :expanded.sync="products"
          hide-default-header
          dense
          :options.sync="options"
          hide-default-footer
          @update:options="fetchProducts"
        >
          >
          <template v-slot:top="{ pagination }">
            <v-data-footer
              :pagination="pagination"
              :items-per-page-options="[5, 10, 15, 20]"
              :options.sync="options"
              @update:options="fetchProducts()"
            />
          </template>
          <template v-slot:[`item.photo`]="{ item }">
            <div class="pa-2 d-flex justify-center">
              <img
                v-if="item.photoUrls[0]"
                :src="item.photoUrls[0]"
                width="40"
                :aspect-ratio="0.75"
                :alt="item.name"
              />
              <gw-svg-icon
                v-else
                svg-name="shirt"
                :width="'40'"
                :height="'54'"
              />
            </div>
          </template>
          <template v-slot:expanded-item="{ headers: head, item }">
            <td
              style="padding: 0px"
              :colspan="head.length">
              <v-simple-table style="width: 100%; border-radius: 0px;">
                <thead>
                  <tr>
                    <th
                      v-for="(haedSku, i) in skusDetails"
                      :key="`skusDetails-${i}`"
                    >
                      {{ haedSku }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(sku, i) in item.skus"
                    :key="`skus-${i}`">
                    <td>
                      <a
                        @click="
                          selectSkuData({ ...sku, productName: item.name })
                        "
                      >{{ sku.code }}</a
                      >
                    </td>
                    <td align="center">
                      {{ sku.size.name }}
                    </td>
                    <td align="center">
                      {{ sku.color.name }}
                    </td>
                    <td align="right">
                      {{ sku.price }}
                    </td>
                    <td align="center">
                      {{ getQtySkuByWarehouse(sku, warehouseSelected) }} ({{
                        getReservedQtySkuByWarehouse(sku, warehouseSelected)
                      }})
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </v-col>
      <v-col
        v-if="selectSku != null"
        cols="12"
        md="6">
        <v-card class="elevation-0 d-flex align-center pa-4">
          <date-picker
            v-model="dateSelected"
            @input="changeStockMovementDate"
          />
        </v-card>
        <div
          class="d-flex"
          style="margin-left: -10px;">
          <v-card class="elevation-0 summary-box">
            <div>Start</div>
            <LoadingMeatBall
              v-if="stockMovementSummary.firstLoading"
              left="0px"
              class="mt-2"
            />
            <div v-else>
              {{ stockMovementSummary.first }}
            </div>
          </v-card>

          <v-card class="elevation-0 summary-box">
            <div>Stock In</div>
            <LoadingMeatBall
              v-if="stockMovementSummary.stockInLoading"
              left="0px"
              class="mt-2"
            />
            <div v-else>
              {{ stockMovementSummary.stockIn }}
            </div>
          </v-card>

          <v-card class="elevation-0 summary-box">
            <div>Ending</div>
            <LoadingMeatBall
              v-if="stockMovementSummary.lastLoading"
              left="0px"
              class="mt-2"
            />
            <div v-else>
              {{ stockMovementSummary.last }}
            </div>
          </v-card>

          <v-card
            class="elevation-0 summary-box summary-box-green cs-pointer"
            @click="exportData"
          >
            <LoadingMeatBall
              v-if="
                stockMovementSummary.lastLoading ||
                  stockMovementSummary.firstLoading ||
                  stockMovementSummary.stockInLoading ||
                  stockMovementSummary.csvLoading
              "
              left="0px"
            />
            <div v-else>
              CSV
            </div>
          </v-card>
        </div>
        <v-card
          v-if="selectSku != null"
          class="elevation-0 d-flex align-center pa-4"
        >
          {{ selectSku.code }}
          <v-spacer />
          <classic-paginate
            :page="stockMovementConfig.page"
            :per-page="stockMovementConfig.perPage"
            :count="stockMovementConfig.total"
            @on-page-change="changeStockMovementPage"
          />
        </v-card>
        <v-card class="elevation-0 stockmovemnent-content">
          <table style="font-size: 12px; width: 500px">
            <tr>
              <th
                width="100"
                align="left">
                Detail
              </th>
              <th width="40">
                Amount
              </th>
              <th width="40">
                Remain
              </th>
            </tr>
            <tr
              v-for="(sm, index) of stockMovementData"
              :key="`sm-${index}`">
              <td>
                {{ sm.createdAt | dateTimeFormat() }} <br />
                {{ mapReferenceType(sm.reference.type) }} ({{
                  sm.createdBy.email
                }})<br />
                <router-link
                  :to="mapReferenceLink(sm.reference)"
                  target="_blank"
                >
                  {{ mapReferenceValue(sm.reference) }}
                </router-link>
              </td>
              <td class="text-right pr-2">
                {{ sm.amount }}
              </td>
              <td class="text-right pr-2">
                {{ sm.onHandQty }}
              </td>
            </tr>
          </table>
        </v-card>
      </v-col>
      <v-col
        v-else
        class="products-content">
        <v-card
          class="elevation-0 d-flex align-center justify-center pa-4"
          style="height: 100%;"
        >
          <div class="text-center">
            เลือก SKU เพื่อแสดงผล
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import ProductProvider from '@/resources/ProductProvider'
import SearchByTagsAndBrand from '@/components/SearchByTagsAndBrand.vue'
import InventoryProvider from '@/resources/InventoryProvider'
import ClassicPaginate from '@/components/ClassicPaginate'
import LoadingMeatBall from '@/components/LoadingMeatBall'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import SizeValues from '@/assets/js/SizeValues'
import DatePicker from '../components/DatesPicker.vue'

const InventoryService = new InventoryProvider()

const ProductService = new ProductProvider()

export default {
  components: {
    SearchByTagsAndBrand,
    LoadingMeatBall,
    DatePicker,
    ClassicPaginate,
    WarehouseSelect
  },
  data () {
    return {
      products: [],
      selectSku: null,
      stockMovementData: [],
      query: {
        search: '',
        tags: [],
        tagOperation: 'OR',
        brand: 'all'
      },
      loading: false,
      isLoadComplete: false,
      warehouseSelected: {
        id: 0,
        name: 'Main',
        code: 'main'
      },
      total: 0,
      stockMovementConfig: {
        page: 1,
        total: 0,
        perPage: 25
      },
      stockMovementSummary: {
        first: 0,
        last: 0,
        stockIn: 0,
        firstLoading: false,
        lastLoading: false,
        stockInLoading: false
      },
      skusDetails: [
        'code',
        'Size',
        'Colour',
        'Price',
        'Stock'
      ],
      headers: [
        { text: 'Photo', value: 'photo', width: 90 },
        { text: 'Name', value: 'name' }
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortDesc: [true],
        sortBy: ['createdAt']
      },
      dateSelected: [this.$dayjs().subtract(6, 'day').format(), this.$dayjs().format()]
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    isMobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    }
  },
  watch: {
    warehouseSelected () {
      this.selectSku = null
    }
  },
  created () {
    this.initial()
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage',
      setSnackbar: 'Components/setSnackbar'
    }),
    sortSize (skus) {
      return skus.sort((a, b) => SizeValues(a.size.name) - SizeValues(b.size.name))
    },
    getQtySkuByWarehouse (sku, warehouse) {
      const stock = sku.stock.find((r) => r.warehouse.id === warehouse.id)
      const onHandQty = stock && stock.onHandQty ? stock.onHandQty : 0
      return onHandQty
    },
    getReservedQtySkuByWarehouse (sku, warehouse) {
      const stock = sku.stock.find((r) => r.warehouse.id === warehouse.id)
      const onReservedQty = stock && stock.onReservedQty ? stock.onReservedQty : 0
      return onReservedQty
    },
    selectSkuData (sku) {
      this.selectSku = sku
      this.fetchStockMovement()
      this.stockMovementConfig = {
        page: 1,
        total: 0,
        perPage: 25
      }
    },
    changeStockMovementPage (page) {
      this.stockMovementConfig.page = page
      this.fetchStockMovement()
    },
    changeStockMovementDate () {
      this.stockMovementConfig = {
        page: 1,
        total: 0,
        perPage: 25
      }
      this.fetchStockMovement()
    },
    async fetchFirstStock () {
      try {
        this.stockMovementSummary.firstLoading = true
        const { data } = await InventoryService.getStockSnapshot({
          sku: this.selectSku.id,
          warehouse: this.warehouseSelected.id,
          date: this.$dayjs(this.dateSelected[0])
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format()
        })
        this.stockMovementSummary.first = data.onHandQty
      } catch (error) {
        console.error('fetchStockMovement', error)
      } finally {
        this.stockMovementSummary.firstLoading = false
      }
    },
    async fetchLastStock () {
      try {
        this.stockMovementSummary.lastLoading = true
        const { data } = await InventoryService.getStockSnapshot({
          sku: this.selectSku.id,
          warehouse: this.warehouseSelected.id,
          date: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format()
        })
        this.stockMovementSummary.last = data.onHandQty
      } catch (error) {
        console.error('fetchStockMovement', error)
      } finally {
        this.stockMovementSummary.lastLoading = false
      }
    },
    async fetchStockIn () {
      try {
        this.stockMovementSummary.stockInLoading = true
        const { data } = await InventoryService.getStockIn({
          sku: this.selectSku.id,
          warehouse: this.warehouseSelected.id,
          startDate: this.$dayjs(this.dateSelected[0])
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format()
        })
        this.stockMovementSummary.stockIn = data
      } catch (error) {
        console.error('fetchStockMovement', error)
      } finally {
        this.stockMovementSummary.stockInLoading = false
      }
    },
    async exportData () {
      if (this.stockMovementSummary.firstLoading
        || this.stockMovementSummary.csvLoading
        || this.stockMovementSummary.lastLoading
        || this.stockMovementSummary.stockInLoading) {
        return
      }
      try {
        this.stockMovementSummary.csvLoading = true

        const { data } = await InventoryService.getStockMovement({
          page: 1,
          limit: 1000,
          skuId: this.selectSku.id,
          warehouse: this.warehouseSelected.id,
          startDate: this.$dayjs(this.dateSelected[0])
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format()
        })

        if (data.total === 0) return

        const rounds = new Array(data.pages).fill(0).map((item, index) => index)

        for await (const round of rounds) {
          const { data: reportsData } = await InventoryService.getStockMovement({
          page: round + 1,
          limit: 1000,
          skuId: this.selectSku.id,
          warehouse: this.warehouseSelected.id,
          startDate: this.$dayjs(this.dateSelected[0])
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format()
          })

          const reports = reportsData.results
          const items = reports.map((report) => ({
            date: this.$dayjs(report.createdAt).format('DD MMM YYYY | HH:mm:ss'),
            type: report.reference.type,
            reference: report.reference.value,
            lastUpdate: report.updatedBy.email,
            add: (report.amount > 0) ? report.amount : '-',
            deduct: (report.amount < 0) ? report.amount : '-',
            remain: report.onHandQty
          }))

          const options = {
            filename: `StockMovement-Report-${round + 1}`,
            showLabels: true,
            useKeysAsHeaders: true
          }

          const csvExporter = new ExportToCsv(options)
          csvExporter.generateCsv(items)
        }
      } catch (error) {
        console.error('exportStockMovement', error)
      } finally {
        this.stockMovementSummary.csvLoading = false
      }
    },
    async fetchStockMovement () {
      this.fetchStockIn()
      this.fetchLastStock()
      await this.fetchFirstStock()
      try {
        const { data } = await InventoryService.getStockMovement({
          page: this.stockMovementConfig.page,
          limit: this.stockMovementConfig.perPage,
          skuId: this.selectSku.id,
          warehouse: this.warehouseSelected.id,
          startDate: this.$dayjs(this.dateSelected[0])
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format()
        })
        this.stockMovementData = data.results
        if (this.stockMovementConfig.page >= data.pages) {
          this.stockMovementData.push({
            amount: 0,
            createdAt: this.$dayjs(this.dateSelected[0])
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format(),
            createdBy: { email: 'system', id: 0 },
            onHandQty: this.stockMovementSummary.first,
            reference: { type: 'init', value: null },
            sku_id: this.selectSku.id,
            transaction_type: 'init'
          })
        }
        this.stockMovementConfig.total = data.total + 1
      } catch (error) {
        console.error('fetchStockMovement', error)
        this.setErrorPage(error.code)
      }
    },
    initial () {
      this.fetchProducts()
    },
    previewImg (img) {
      this.currentImgPreview = img
      this.previewEnabled = !!this.currentImgPreview
    },
    mapReferenceType (type) {
      switch (type) {
        case 'billstockdeduct':
          return 'Bill stock deduct'
        case 'createproduct':
          return 'Create product'
        case 'developcheckstock':
          return 'Develop checkstock'
        case 'withdrawnote':
          return 'Withdraw note'
        case 'deliverynote':
          return 'Delivery note'
        case 'migration':
          return 'Migration'
        case 'manualadjust':
          return 'Manualadjust'
        case 'voidbill':
          return 'Void bill'
        default:
          return type
      }
    },
    mapReferenceValue (reference) {
      if (reference.documentNo) {
        return reference.documentNo
      }
      switch (reference.type) {
        case 'withdrawnote':
          return reference.value ? `WN${ reference.value.toString().padStart(6, '0') }` : ''
        case 'deliverynote':
          return reference.value ? `DN${ reference.value.toString().padStart(6, '0') }` : ''
        default:
          return reference.value
      }
    },
    mapReferenceLink (reference) {
      if (reference.type === 'withdrawnote') {
        return {
          name: 'WithdrawNote',
          query: {
            id: reference.value
          }
        }
      }
      if (reference.type === 'deliverynote') {
        return {
          name: 'DeliveryNote',
          query: {
            id: reference.value
          }
        }
      }
      if (reference.type === 'billstockdeduct' || reference.type === 'voidbill' || reference.type === 'bill_reserve') {
        const isSubOrder = reference.value.includes('-')
        return `/order/all?channel=all&id=${reference.value}&is_main=${!isSubOrder}`
      }

      return {
        name: 'Home'
      }
    },
    searchProduct () {
      this.options.page = 1
      this.fetchProducts()
    },
    async fetchProducts () {
      this.loading = true
      try {
        const { data } = await ProductService.getProducts({
          page: this.options.page,
          limit: this.options.itemsPerPage,
          search: this.query.search,
          tags: this.query.tags,
          tagOperation: this.query.tagOperation,
          brand: this.query.brand
        })
        this.total = data.total
        this.products = data.results.map((re) => ({
          ...re,
          skus: this.sortSize(re.skus)
        }))
      } catch (error) {
        console.error('fetchProducts', error)
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.products-content {
  height: calc(100vh - 200px);
  overflow: auto;
}
.stockmovemnent-content th {
  border-bottom: 1px solid #cfcfcf;
}
.stockmovemnent-content tr:nth-child(even) {
  background-color: #f2f2f2;
}
.stockmovemnent-content {
  padding: 10px;
  height: calc(100vh - 460px);
  overflow: auto;
}
.summary-box-green {
  background-color: #207346 !important;
}

.summary-box {
  background-color: black;
  color: #fff;
  height: 100px;
  width: 100px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
